<template>
    <!-- <h2 class="fade-in-top">Slike pre i posle upotrebe: </h2> -->
    <div class="before-after-wrap">
      <div class="title-holder fade-in-top">
        <div>
          <h2>PRE UPOTREBE</h2>
        </div>
        <div>
          <h2>POSLE UPOTREBE</h2>
        </div>
      </div>
      <div class="before-and-after fade-in-top" v-for="img in images" :key="img.id">
        <div>
          <img :src="img.beforeImgSrc" :alt="img.beforeImgName">
        </div>
        <div>
          <img :src="img.afterImgSrc" :alt="img.afterImgName">
        </div>
      </div>
    </div>
</template>

<script>

export default {
  data() {
    return{
    }
  },
  computed:{
    images () {
      return this.$store.state.beforeAndAfterImages;
    }
  }
}

</script>

<style lang="scss" scoped>
  .before-after-wrap{
    width: 100%;
    .title-holder {
      display: flex;
      justify-content: center;
      width: 100%;
      div {
        width: 500px;
        margin: 0;
        h2 {
          margin: 0;
        }
      }
    }

    .before-and-after {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      div {
        border-radius: 3px;
        margin: 10px;
        display: flex;
        justify-content: center;
        width: 500px;
        img {
          width: 500px;
          border-radius: 3px;
        }
      }
    }
    
    
  }
  
  @media screen and (max-width: 500px) {
    h2 {
      font-size: 17px;
    }
  }

</style>